import React from "react";
import Accordion from "starkeydigital/React/Components/accordion";
import Row from "starkeydigital/React/Components/row";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const WomensLeadership: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Women's Development"
      titleText2=""
      slogan="We have been designing and delivering women's leadership programmes for many years."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Women's Development"
        text="We are widely regarded as experts in the field of developing female leaders. 
        We have designed and run programmes for some of the world's leading law, accounting and consulting firms and many international organisations.
        We adopt a distinctive approach, always working flexibly with the topics and agendas
        brought by both the client organisation and the individual
        participants. "
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <AnimateUp>
              <Accordion.Item title="Why would you invest in Women's Leadership Programmes?">
                Your talent pipeline is not delivering the female leaders your
                organisation needs for your future plans / Your clients or
                customers view you as old fashioned or not embodying their
                values because of the gender mix in your senior team / High
                flying women in your organisation need additional support or
                challenge to fulfil their potential / You are suffering from a
                significant loss of female talent / High potential female
                recruits are questioning whether your organisation is the place
                they can make their future career
              </Accordion.Item>
            </AnimateUp>
            <AnimateUp>
              <Accordion.Item title="How would Women's Leadership Programmes work?">
                Initial consultation to help you understand the most effective
                way to meet your needs and deliver value to the business /
                Working with your women, men and the organisation (a three track
                approach) / Delivering (or bringing a team together to deliver)
                the programme that meets your needs, educates you about the
                specific gender related problems faced within the organisation
                and leverages our expertise to help you level the playing field.
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="Examples of work done">
                Linklaters global Women’s Leadership Programme - since 2012, and
                Women’s Associates Leadership Programmes – since 2018. Allen and
                Overy global Women’s Leadership programme - since 2016, and
                Mid-Level Associates Leadership Programmes – since 2019.
                Shoosmiths Women’s Leadership programme – since 2019. Excellence
                in Sponsorship programme and Diversity and Inclusion programmes
                – introduced in 2021.
              </Accordion.Item>
            </AnimateUp>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default WomensLeadership;
